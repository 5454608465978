import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lang-box" }

import { lang } from '@/lang/index';

import { Popover } from 'vant';

import { ref, computed } from 'vue';
type LanguageItem = {
  key: string;
  text: string;
};

export default /*@__PURE__*/_defineComponent({
  __name: 'Language',
  setup(__props) {

const LanguageList: LanguageItem[] = [
  {
    key: 'en',
    text: 'English',
  },
  {
    key: 'hi',
    text: 'Hindi',
  },
  {
    key: 'in',
    text: 'Indonesian',
  },
  {
    key: 'pt',
    text: 'Português (BR)',
  },
  {
    key: 'vi',
    text: 'Vietnamese',
  },
];

const selecting = ref(false);

const selected = computed(() => {
  return LanguageList.find(item => item.key === lang.m);
});

const onSelect = (item: LanguageItem) => {
  lang.change(item.key);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Popover), {
      modelValue: selecting.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selecting).value = $event)),
      onSelect: onSelect,
      theme: "dark",
      actions: LanguageList,
      offset: [-30, 8]
    }, {
      reference: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(selected.value?.text), 1)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}
}

})