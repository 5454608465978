import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "downloader" }
const _hoisted_2 = { class: "icon center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "info" }
const _hoisted_5 = { class: "tip" }
const _hoisted_6 = { class: "name" }

import { lang } from '@/lang';


export default /*@__PURE__*/_defineComponent({
  __name: 'Downloader',
  props: {
    name: {},
    icon: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: props.icon,
        class: "img"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(lang).v.go), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(props.name), 1)
    ])
  ]))
}
}

})