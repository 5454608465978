import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, unref as _unref, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "founding mt-12" }
const _hoisted_2 = { class: "item" }
const _hoisted_3 = { class: "name ph14" }
const _hoisted_4 = { class: "info ph14" }
const _hoisted_5 = { class: "item f84" }
const _hoisted_6 = { class: "super-v" }
const _hoisted_7 = { class: "item f84" }
const _hoisted_8 = { class: "v" }
const _hoisted_9 = { class: "item f151" }
const _hoisted_10 = { class: "v" }
const _hoisted_11 = { class: "views mt-12 ph14" }
const _hoisted_12 = { class: "state mt-12 ph14" }
const _hoisted_13 = { class: "progress" }
const _hoisted_14 = { class: "bar" }
const _hoisted_15 = { class: "value" }

import { computed } from 'vue';
import { InvestmentItem } from '@/api/protocol';
import dataCenter from '@/dataCenter';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props;
const data = computed(() => props.data);

const onBuyInvestment = () => {
  //
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: "",
        class: "cover"
      }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(data.value.name), 1),
        _createElementVNode("span", null, _toDisplayString(data.value.endTime), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(data.value.income), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "k" }, "年化收益率", -1))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(data.value.userMinAmount), 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "k" }, "起投金额", -1))
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, _toDisplayString(data.value.progressMoney) + "/" + _toDisplayString(data.value.totalMoney), 1),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "k" }, "已完成/总金额", -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_11, _toDisplayString(data.value.virtualViews), 1),
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text ph14" }, "短剧当日观看次数", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "text mt-12 ph14" }, " *根据推广产生的实际效果，收益每日会产生浮动。 ", -1)),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", {
              class: "len",
              style: _normalizeStyle({ width: data.value.progress })
            }, null, 4)
          ]),
          _createElementVNode("div", _hoisted_15, _toDisplayString(data.value.progress), 1)
        ]),
        _withDirectives(_createElementVNode("div", {
          class: "btn buy center",
          onClick: onBuyInvestment
        }, " 立即认购 ", 512), [
          [_vShow, _unref(dataCenter).user.data]
        ])
      ])
    ])
  ]))
}
}

})