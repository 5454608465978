import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top" }
const _hoisted_2 = { class: "right" }

import { useRouter } from 'vue-router';
import dataCenter from '@/dataCenter';
import Language from './Language.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'Head',
  setup(__props) {

const user = dataCenter.user;

const router = useRouter();

function onClickLogo() {
  router.replace('/');
}
function onGotoLogin() {
  //
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "left",
      onClick: onClickLogo
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Language)
    ])
  ]))
}
}

})