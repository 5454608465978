import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/img/banner_other.png'


const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "panel" }
const _hoisted_3 = { class: "row-center" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = { class: "sub-pl" }
const _hoisted_7 = { class: "title" }
const _hoisted_8 = { class: "li" }
const _hoisted_9 = { class: "li" }
const _hoisted_10 = { class: "li" }
const _hoisted_11 = { class: "sub-pl mt-12" }
const _hoisted_12 = { class: "title" }
const _hoisted_13 = { class: "li" }
const _hoisted_14 = { class: "panel" }
const _hoisted_15 = { class: "row-center" }
const _hoisted_16 = { class: "name" }
const _hoisted_17 = { class: "panel" }
const _hoisted_18 = { class: "row-center" }
const _hoisted_19 = { class: "name" }
const _hoisted_20 = { class: "downloaders" }
const _hoisted_21 = { class: "panel" }
const _hoisted_22 = { class: "row-center" }
const _hoisted_23 = { class: "name" }
const _hoisted_24 = { class: "downloaders" }

import { fetchInvestmentList } from '@/api/fetcher';
import Head from '@/components/Head.vue';
import Tabbar from '@/components/Tabbar/tabbar.vue';
import Founding from '@/components/Founding/index.vue';
import Downloader from './Downloader.vue';
import Feature from './Feature.vue';
import { lang } from '@/lang';
import { onMounted, reactive, ref, computed } from 'vue';
import { InvestmentItem } from '@/api/protocol';
import { useRouter } from 'vue-router';
import AppleIcon from '@/assets/img/Android_white.png';
import AndroidIcon from '@/assets/img/Apple_white.png';
import H5Icon from '@/assets/img/Apple_white.png';

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const router = useRouter();
const investments = ref<InvestmentItem[]>();
const userinfo = ref({
  talentCardType: 0,
});
const downloads = reactive({ ios: '', android: '', h5: '' });

onMounted(() => {
  downloads.ios = window.download.ios;
  downloads.android = window.download.android;
  downloads.h5 = window.download.h5;
  fetchInvestmentList({ status: 1, page: 1, limit: 2 }).then(resp => {
    if (resp) {
      investments.value = resp.list;
    }
  });
});

const gointelligent = () => {
  userinfo.value = JSON.parse(localStorage.getItem('userInfo') || '{}');
  if (userinfo.value == null) {
    router.push('/intelligent');
  } else {
    if (
      userinfo.value.talentCardType == null ||
      userinfo.value.talentCardType == 0
    ) {
      router.push('/intelligent');
    } else {
      router.push('/become_intelligent');
    }
  }
};

const gocopyright = () => {
  router.push('/copyright');
};
const onDownloadIos = () => {
  window.open(window.download.ios, '_blank');
};
const onDownloadAndroid = () => {
  window.open(window.download.android, '_blank');
};
const onGotoH5 = () => {
  window.location.href = window.download.h5;
};

const features = computed(() => [
  {
    tips: [lang.v.name1, lang.v.describe1],
    icon: require('@/assets/img/logo1.png'),
  },
  {
    tips: [lang.v.name2, lang.v.describe2],
    icon: require('@/assets/img/logo2.png'),
  },
  {
    tips: [lang.v.name3, lang.v.describe3],
    icon: require('@/assets/img/logo3.png'),
  },
  {
    tips: [lang.v.name4, lang.v.describe4],
    icon: require('@/assets/img/logo4.png'),
  },
]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Head),
    _cache[5] || (_cache[5] = _createElementVNode("img", {
      class: "big-logo",
      src: _imports_0
    }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "line" }, null, -1)),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(lang).v.starcard), 1)
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(lang).v.describe), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(lang).v.starReward), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(lang).v.text1), 1),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(lang).v.text2), 1),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(lang).v.text3), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(lang).v.starClu), 1),
        _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(lang).v.text4), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createElementVNode("div", _hoisted_15, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "line" }, null, -1)),
        _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(lang).v.copyright), 1)
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("div", null, null, -1)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(investments.value, (item) => {
        return (_openBlock(), _createBlock(Founding, {
          data: item,
          key: item.id
        }, null, 8, ["data"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "line" }, null, -1)),
        _createElementVNode("span", _hoisted_19, _toDisplayString(_unref(lang).v.download), 1)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _withDirectives(_createVNode(Downloader, {
          onClick: onDownloadIos,
          name: _unref(lang).v.apple,
          icon: _unref(AndroidIcon)
        }, null, 8, ["name", "icon"]), [
          [_vShow, downloads.ios]
        ]),
        _withDirectives(_createVNode(Downloader, {
          onClick: onDownloadAndroid,
          name: _unref(lang).v.android,
          icon: _unref(AppleIcon)
        }, null, 8, ["name", "icon"]), [
          [_vShow, downloads.android]
        ]),
        _withDirectives(_createVNode(Downloader, {
          onClick: onGotoH5,
          name: "H5版本",
          icon: _unref(H5Icon)
        }, null, 8, ["icon"]), [
          [_vShow, downloads.h5]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_21, [
      _createElementVNode("div", _hoisted_22, [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "line" }, null, -1)),
        _createElementVNode("span", _hoisted_23, _toDisplayString(_unref(lang).v.Characteristic), 1)
      ]),
      _createElementVNode("div", _hoisted_24, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(features.value, (item, i) => {
          return (_openBlock(), _createBlock(Feature, {
            tips: item.tips,
            icon: item.icon,
            key: i
          }, null, 8, ["tips", "icon"]))
        }), 128))
      ])
    ]),
    _createVNode(Tabbar)
  ]))
}
}

})