import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "feature col-center" }
const _hoisted_2 = ["src"]



export default /*@__PURE__*/_defineComponent({
  __name: 'Feature',
  props: {
    tips: {},
    icon: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: props.icon,
      class: "icon"
    }, null, 8, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tips, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "tip",
        key: item
      }, _toDisplayString(item), 1))
    }), 128))
  ]))
}
}

})